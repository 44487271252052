import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { injectIntl } from "gatsby-plugin-react-intl"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import TitleSection from "../components/TitleSection"
import {
  sectionContact,
  row
} from "../assets/style/pages/contact.module.scss"

const ContactPage = ({ data, intl }) => {
  const { title, subtitle, address, phone, phone2, mail, mapUrl } = data.cockpitContactPage
  const featuredImage = data.cockpitContactPage.featuredImage.value.childImageSharp.fluid
  const mapImage = getImage(data.cockpitContactPage.mapImage.value)

  return (
    <Layout>
      <Seo lang={intl.locale} title={intl.formatMessage({ id: "contactPage" })} />
      <TitleSection title={title.value} subtitle={subtitle.value} bg={featuredImage}></TitleSection>
      <section className={`section ${sectionContact}`}>
        <div className="container">
          <div className={`row ${row}`}>
            <div className="col-12 col-lg-4">
              <address>
                <div>
                  <p>
                    <strong>{intl.formatMessage({ id: "address" })}:</strong>
                    <a href={mapUrl.value} target="_blank" rel="noopener noreferrer">{address.value}</a>
                  </p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path d="M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z"/>
                  </svg>
                </div>
                <div>
                  <p>
                    <strong>{intl.formatMessage({ id: "inquiries" })}:</strong>
                    <a href={`tel:${phone.value}`}>{phone.value}</a>
                  </p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path d="M20 22.621l-3.521-6.795c-.008.004-1.974.97-2.064 1.011-2.24 1.086-6.799-7.82-4.609-8.994l2.083-1.026-3.493-6.817-2.106 1.039c-7.202 3.755 4.233 25.982 11.6 22.615.121-.055 2.102-1.029 2.11-1.033z"/>
                  </svg>
                </div>
                <div>
                  <p>
                    <strong>{intl.formatMessage({ id: "office" })}:</strong>
                    <a href={`tel:${phone2.value}`}>{phone2.value}</a>
                  </p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path d="M20 22.621l-3.521-6.795c-.008.004-1.974.97-2.064 1.011-2.24 1.086-6.799-7.82-4.609-8.994l2.083-1.026-3.493-6.817-2.106 1.039c-7.202 3.755 4.233 25.982 11.6 22.615.121-.055 2.102-1.029 2.11-1.033z" />
                  </svg>
                </div>
                <div>
                  <p>
                    <strong>{intl.formatMessage({ id: "email" })}:</strong>
                    <a href={`mailto:${mail.value}`}>{mail.value}</a>
                  </p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path d="M12 12.713l-11.985-9.713h23.97l-11.985 9.713zm0 2.574l-12-9.725v15.438h24v-15.438l-12 9.725z"/>
                  </svg>
                </div>
              </address>
            </div>
            <div className="col-12 col-lg-7 offset-lg-1 col-xl-6 col-xxl-5">
              <GatsbyImage image={mapImage} alt="Mapa" />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query($lng: String) {
    cockpitContactPage(lang: { eq: $lng }) {
      title {
        value
      }
      subtitle {
        value
      }
      address {
        value
      }
      phone {
        value
      }
      phone2 {
        value
      }
      mail {
        value
      }
      mapUrl {
        value
      }
      featuredImage {
        value {
          childImageSharp {
            fluid(maxWidth: 2560) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      mapImage {
        value {
          childImageSharp {
            gatsbyImageData(
              width: 763,
              placeholder: BLURRED,
              quality: 100
            )
          }
        }
      }
    }
  }
`

export default injectIntl(ContactPage)
